import React, { useState } from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import Layout from "../components/layout/en"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import WeeklyMenu from "../components/restaurant-menu/weekly-menu"

const MenuPage = (props) => {
	const seoDescription = "To celebrate the 50th anniversary of our home, from Tuesday to Friday we offer you a menu where you can taste seasonal dishes that have accompanied us throughout these years.";
	const entrants = "Starters";
	const platsPrincipals = "Main dishes";
	const postres = "Dessert";
	const ivaInclos = "VAT included";
	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Weekday menu"
	    	lang="en"
	    	description={seoDescription}
	    />
	    <HeroImage 
	      titleText="Weekday menu"
	      image="carta" 
	    />
	    <WeeklyMenu 
	    	lang='en'
	    	entrants={entrants}
	    	principals={platsPrincipals}
	    	postres={postres}
	    	iva={ivaInclos}
	    />
	    <Link to="/carta">
	    	<h2>See the Menu</h2>
	    </Link>
	  </Layout>
	)
}

const MenuSection = styled.div`
	margin: 140px 70px;
	display: flex;
	@media (max-width: 540px){
		margin: 100px 5px;
	  flex-direction: column;
	}
`

const SelectBox = styled.select`
	font-size: 16px;
	@media (min-width: 540px){
	  display: none;
	}
`

const MenuHeaders = styled.div`
	display: flex;
	flex-direction: column;
	list-style-type: none;
	width: 250px;
	li {
	  cursor: pointer;
	  padding: 10px;
	  line-height: 20px;
	}
	@media (max-width: 540px){
	  display: none;
	}
`

export default MenuPage